<template>
 <section class="invoice">
    <!-- <div class="page-header">
      <h3 class="page-title"> Invoice </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">E-commerce</a></li>
          <li aria-current="page" class="breadcrumb-item active">Invoice</li>
        </ol>
      </nav>
    </div> -->
    <div class="row ">
      <div class="col-lg-12 ">
        <div class="card px-2">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-6">
                   <img src="@/assets/images/logo-new.png" alt="logo" class="my-4" />
                </div>
                <div class="col-6">
                   <h3 class="text-right my-5">Invoice&nbsp;&nbsp;#INV-17</h3>
                </div>
              </div>
             
              <hr>
            </div>
            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-3 pl-0">
                <p class="mt-5 mb-2"><b> Shoplattice</b></p>
                <p>816, Park Lane Tower, Business Bay,<br> Dubai.  +971 52 104 6915,  <br>askus@shoplattice.com</p>
              </div>
              <div class="col-lg-3 pr-0">
                <p class="mt-5 mb-2 text-right"><b>Invoice to</b></p>
                <p class="text-right">Muhammed Fazil <br>HiLITE Business Park, <br>Calicut, Kerala. <br>9645304378</p>
              </div>
            </div>
            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-3 pl-0">
                <p class="mb-0 mt-4">Invoice Date : 28rd Jan 2022</p>
                <p>Due Date : 31th Jan 2022</p>
              </div>
            </div>
            <div class="container-fluid mt-4 d-flex justify-content-center w-100">
              <div class="table-responsive w-100">
                <table class="table">
                  <thead>
                    <tr class="bg-dark text-white">
                      <th>#</th>
                      <th>Description</th>
                      <th class="text-right">Quantity</th>
                      <th class="text-right">Unit cost</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-right">
                      <td class="text-left">1</td>
                      <td class="text-left">Women Yellow & White Printed Kurta with Palazzos</td>
                      <td>1</td>
                      <td>999</td>
                      <td>999</td>
                    </tr>
                    <tr class="text-right">
                      <td class="text-left">2</td>
                      <td class="text-left">Men Yellow Printed Cotton Pure Cotton T-shirt</td>
                      <td>1</td>
                      <td>301</td>
                      <td>301</td>
                    </tr>
                    <tr class="text-right">
                      <td class="text-left">3</td>
                      <td class="text-left">Boys Blue Tapered Fit Light Fade Jeans</td>
                      <td>1</td>
                      <td>450</td>
                      <td>450</td>
                    </tr>
                    <tr class="text-right">
                      <td class="text-left">4</td>
                      <td class="text-left">Girls Blue Floral Printed Cotton Empire Dress</td>
                      <td>1</td>
                      <td>250</td>
                      <td>250</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="container-fluid mt-5 w-100">
              <p class="text-right mb-2">Sub - Total: 2000</p>
              <p class="text-right mb-2 ">Discount  (11%): 220</p>
              <p class="text-right mb-2">Shipping Charges: 65</p>
              <p class="text-right">vat (5%) : 100</p>
              <h4 class="text-right mb-5">Total : 1945</h4>
              <hr>
            </div>
            <div class="container-fluid w-100"><a   @click.prevent="print()" href="javascript:void(0);"
                class="btn btn-primary float-right mt-4 ml-2"><i class="mdi mdi-printer mr-1"></i>Print</a>
                <!-- <a
                href="javascript:void(0);" class="btn btn-success float-right mt-4"><i
                  class="mdi mdi-telegram mr-1"></i>Send Invoice</a> -->
                  </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "invoice",
  components: {},
  data() {
    return {};
  },
   methods: {
  print()  {
      window.print();
  }}
};

</script>

